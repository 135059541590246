"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const HelperUtils_1 = require("./HelperUtils");
String.prototype.h5locale = function () {
    const appType = localStorage.getItem('appType');
    const parsedAppType = appType === 'null' ? null : JSON.parse(appType);
    return parsedAppType === 'culture'
        ? (0, HelperUtils_1.getCultureLocale)(String(this))
        : (0, HelperUtils_1.getObjectiveLocale)(String(this));
};
String.prototype.replaceLocale = function (replacements) {
    let res = String(this);
    for (const placeholder in replacements) {
        res = res.replace(`[${placeholder}]`, replacements[placeholder]);
    }
    return res;
};
